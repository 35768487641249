export default function Header({ heading, subheading }) {
  return (
    <div className="lg:mt-24 mt-10 container md:w-3/5 p-12 rounded-lg mx-auto ">
      <div className="flex justify-center mb-5">
        <img src="/vcheck.png" alt="Police Logo"  width="500" height="200" className="h-100 w-100" />
      </div>
      <h4 className="text-center text-sm lg:text-2xl font-bold">{heading}</h4>
      <h4 className="text-center text-sm lg:text-2xl mt-2 font-bold">{subheading}</h4>
    </div>
  );
}
